<template>
  <tr>
    <td>
      {{ alert.dateToLocal() }}
    </td>
    <td>
      <a target="_blank" rel="noopener noreferrer" :href="$generateLinkDatastore(item.id, alert.getDspName())">
        <v-icon>launch</v-icon> Go to datastore
      </a>
    </td>
    <td>
      {{item.id}}
    </td>
    <td>
      {{item.error_messages.join(',')}}
    </td>
  </tr>
</template>

<script>
import UltraDetailedMixin from './mixin/UltraDetailedMixin'

export default {
  mixins: [UltraDetailedMixin],
  name: 'WrongSyntax',
  props: [],
  components: {

  },
  data: function () {
    return {

    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
  .temp_WrongSyntaxDetailed.fixed-headers td, .temp_WrongSyntaxDetailed.fixed-headers th {
    /* width: 8em; */
    width: 12em;
  }
</style>
